import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from 'gatsby';
import { P, PageHeading } from '../components/typography';
import { GatsbyImage, getSrc } from "gatsby-plugin-image";

const imgNameFromSrc = (src) => {
  // src - a file name, e.g. '/static/files/images/portrait.jpg'
  return src.substring(src.lastIndexOf('/') + 1)
}

const EDGuide = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: {
            type: {
              eq: "blog_post"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              description
              imageName
              type
            }
          }
        }
      }
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 1000, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
            gatsbyImageData(
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
              width: 600
              aspectRatio: 1.5
            )
          }
        }
      }
    }
  `)

  return (
    <Layout>
          <SEO title="ED Information Guide" />
          <div className="desktop-max-width" >
            <div className="px-6" style={{maxWidth: 1000}}>
              <div className="mb-2"><PageHeading >ED Information Guide</PageHeading></div>
              <P>Here we provide treatment and health information related to erectile dysfunction.</P>
              <div className="blog-tile-container mt-4">
                {data.allMdx.edges.map((edge, index) => {
                  const imageName = edge.node.frontmatter.imageName
                  const matchedImage = data.allImageSharp.edges.filter(
                    x => imgNameFromSrc(getSrc(x.node.gatsbyImageData)) === imageName
                  )[0].node.gatsbyImageData
                  return (
                    <div className="blog-tile w-full md:w-1/2 md:max-w-sm ">
                      <div 
                        className="h-full rounded overflow-hidden shadow-lg" 
                      >
                        <Link to={edge.node.frontmatter.slug}>
                          <div className="">
                            <div className="blog-tile-bg">
                              <GatsbyImage
                                image={matchedImage} 
                                alt="hey"
                              />
                            </div>
                            <div className="px-2 py-2 font-bold text-lg">
                              <Link to={edge.node.frontmatter.slug}>{edge.node.frontmatter.title}</Link>
                            </div>
                            <div className="px-2 mb-4">{edge.node.frontmatter.description}</div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
    </Layout>
  )
}

export default EDGuide
